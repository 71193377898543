const navbar = {
  navBgColor: "var(--color-background)",
  navShadow: "0px 1px 2px var(--color-dark)",
  select: document.querySelector("nav"),
  toggler: document.querySelector(".navbar-toggler"),
  links: document.querySelector("#navbarLinks"),
  hide() {
    let { select } = this;
    if (!select.hidden) select.hidden = true;
  },
  unhide() {
    let { select } = this;
    if (select.hidden) select.hidden = false;
  },
  setBgColor(color = this.navBgColor) {
    let { select } = this;
    select.style.backgroundColor = color;
  },
  clearBgColor() {
    this.setBgColor("");
  },
  setShadow(shadow = this.navShadow) {
    let { select } = this;
    select.style.boxShadow = shadow;
  },
  noShadow() {
    this.setShadow("none");
  },
};

const form = {
  select: document.querySelector("form"),
  inputs: document.querySelectorAll("form input"),
  submit: document.querySelector("#formSubmitBtn"),
  disableSubmit() {
    let { submit } = this;
    submit.setAttribute("disabled", "true");
  },
  enableSubmit() {
    let { submit } = this;
    submit.removeAttribute("disabled");
  },
};

function isBottomOfPage() {
  return scrollY >= document.body.offsetHeight - window.innerHeight;
}

function isNavTogglerExpanded() {
  for (let attr of navbar.toggler.attributes) {
    if (attr.name === "aria-expanded" && attr.value === "true") {
      return true;
    }
  }
  return false;
}

function collapseNavLinks() {
  let { toggler, links } = navbar;
  toggler.classList.add("collapsed");
  toggler.setAttribute("aria-expanded", "false");
  links.classList.remove("show");
}

// Set the navbar properties according to scroll height
// and whether toggler is expanded.
function setNavbar() {
  if (!isBottomOfPage()) {
    if (!isNavTogglerExpanded() && scrollY <= 0) {
      navbar.clearBgColor();
      navbar.noShadow();
    } else {
      navbar.setBgColor();
      navbar.setShadow();
    }
    navbar.unhide();
  } else {
    navbar.hide();
  }
}

//
// EVENT HANDLERS
//
window.onpageshow = () => setNavbar();
window.onhashchange = () => collapseNavLinks();
navbar.toggler.onclick = () => setNavbar();

document.onscroll = () => {
  setNavbar();
  collapseNavLinks();
};

form.select.oninput = () => {
  let isEmpty = true;
  for (let input of form.inputs) {
    if (input.value) isEmpty = false;
  }
  isEmpty ? form.disableSubmit() : form.enableSubmit();
};
